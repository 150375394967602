import React from "react";
import Card from 'react-bootstrap/Card';
import { motion } from "framer-motion";

export function LastRow() {

    const variants = {
        move: i => ({
            x: [
                0,
                100, 100, 100, 100, 100,
                0, 0, 0, 0, 0,
                // 0,
                // 100, 100, 100, 100, 100, 100, 100, 100, 100, 100,
                // 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,                
            ],
            rotate: [
                0,
                720, 720, 720, 720, 720,
                0, 0, 0, 0, 0,
                // 0,
                // 720, 720, 720, 720, 720, 720, 720, 720, 720, 720, 720, 720, 720, 720, 720,
                // 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,

            ],           
            transition: { delay: i * 0.4, duration: 15, repeat: Infinity }
        })
    }

    return (
        <div>
            <Card className='card-lastrow'>
                <Card.Body>
                    <Card.Title></Card.Title>
                    <Card.Text style={{ display: 'flex' }}>
                        <motion.span animate='move' variants={variants} custom={24}>
                            A
                        </motion.span>
                        <motion.span animate='move' variants={variants} custom={23}>
                            N
                        </motion.span>
                        <motion.span animate='move' variants={variants} custom={22}>
                            I
                        </motion.span>
                        <motion.span animate='move' variants={variants} custom={21}>
                            M
                        </motion.span>
                        <motion.span animate='move' variants={variants} custom={20}>
                            A
                        </motion.span>
                        <motion.span animate='move' variants={variants} custom={19}>
                            T
                        </motion.span>
                        <motion.span animate='move' variants={variants} custom={18}>
                            I
                        </motion.span>
                        <motion.span animate='move' variants={variants} custom={17}>
                            O
                        </motion.span>
                        <motion.span animate='move' variants={variants} custom={16}>
                            N
                        </motion.span>
                        <motion.span animate='move' variants={variants} custom={15}>
                            S
                        </motion.span>
                      
                    </Card.Text>
                    Aleksander Ligeza-Salamon
                    <br />ollo7776@gmail.com
                </Card.Body>
            </Card>
        </div>
    )
}
