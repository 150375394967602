import React, { useState } from "react";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import './BusAnimation.css'
import Modal from 'react-bootstrap/Modal';
import { BusSmall } from "./BusSmall";
import { BusAnimation } from "./BusAnimation";

export function CardBus() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <div>
                <Card className='card-sm'>
                    <Card.Body>
                        <Card.Title>Animation mit Bus</Card.Title>
                        <Card.Text>
                            <BusSmall />
                        </Card.Text>
                    </Card.Body>
                    <Button onClick={handleShow} >
                        START THE GAME
                    </Button>
                </Card>
            </div>
            <Modal size="lg" show={show} onHide={handleClose} animation={false} >
                <Modal.Header closeButton>
                    <Modal.Title>Bus Animation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <BusAnimation />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

        </>


    )
}
