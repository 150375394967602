
import React, { useState } from "react";
import './BusAnimation.css'

export function BusSmall() {
    const [moveStyle] = useState({
        animationPlayState: 'paused'
    })

    return (
        <>
            <div className="bus-small">
                <div id="bus" style={moveStyle}></div>
                <div id="busWheelRear" style={moveStyle}></div>
                <div id="busWheelFront" style={moveStyle}></div>
                <div id="busMirror" style={moveStyle}></div>
            </div>
        </>


    )
}