import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

export function Clues() {
    const [isMove, setIsMove] = useState(true);
    const controls = useAnimation()

    let width = 100
    let height = 100

    const variants = {
        initial: {
            pathLength: 1,
            opacity: 1
        }
    }

    useEffect(() => {
        if (isMove) {
            controls.start({
                opacity: 1,
            })
            controls.start({
                pathLength: 1,
                transition: { duration: 2 },
            })
        } else {
            controls.start({
                pathLength: 0,
                transition: { duration: 2 },
            })
            controls.start({
                opacity: 0, transition: { delay: 2 }
            })
        }
    }, [isMove, controls])


    return (
        <div>
            <Card className='card-sm'>
                <Card.Body>
                    <Card.Title>ECO</Card.Title>
                    <Card.Text>

                        <span className="circle-line-rect">
                            <motion.svg
                                viewBox="-5 -6 30 30"
                                width={width} height={height}
                            // animate={{ opacity: isMove ? 1 : 0, }}
                            // transition={{ delay: 1, duration: 1 }}                    
                            >
                                <motion.path
                                    d="M10 6C2 6 2 12 8 20L4 20C0 14-2 2 10 4L10 0 14 5 10 10 10 6M9 15 13 20 13 16C25 18 23 7 19 0L15 0C21 9 21 14 13 14L13 10 9 15"
                                    //fill="transparent"
                                    strokeWidth="1"
                                    stroke="green"
                                    fill='lightgreen'
                                    strokeLinecap="round"
                                    variants={variants}
                                    initial='initial'
                                    animate={controls}
                                // animate={{ pathLength: isMove ? 1 : 0, }}
                                // transition={{ duration: 1 }}
                                />
                            </motion.svg>
                        </span>

                    </Card.Text>

                </Card.Body>
                <Button onClick={() => setIsMove(!isMove)}>
                    <svg
                        viewBox="-3 -4 30 30"
                        width='25' height='25'
                    >
                        <path
                            d="M10 6C2 6 2 12 8 20L4 20C0 14-2 2 10 4L10 0 14 5 10 10 10 6M9 15 13 20 13 16C25 18 23 7 19 0L15 0C21 9 21 14 13 14L13 10 9 15"
                            strokeWidth="1"
                            stroke="green"
                            fill='lightgreen'
                            strokeLinecap="round"
                        />
                    </svg>
                    Animate
                </Button>
            </Card>
        </div>
    )
}
