import React, { useEffect, useState, useRef } from "react";
import { motion } from "framer-motion";

export function SpielchenMini() {
    const xBallRef = useRef(30)
    const yBallRef = useRef(20)
    const xMoveRef = useRef(1)
    const yMoveRef = useRef(-1)
    const [moving, setMoving] = useState(true)
    const [move, setMove] = useState(25)
    const [score, setScore] = useState(0)
    const [seconds, setSeconds] = useState(0)

    useEffect(() => {
        if (moving) {
            const interval = setInterval(() => {

                setSeconds(seconds => seconds + 1)
                // console.log(yBallRef.current)
                console.log(xBallRef.current)
                if (xBallRef.current === -20) {
                    xMoveRef.current = 1
                }
                if (xBallRef.current === 99) {
                    xMoveRef.current = -1
                }
                if (yBallRef.current === -1) {
                    yMoveRef.current = 1
                }
                if (yBallRef.current === 60) {
                    yMoveRef.current = -1
                }
                if (xBallRef.current === -19) {
                    clearBall()
                }

                // change the ball movement
                if (score === 2 && xBallRef.current === 4 && yBallRef.current > 17 && xMoveRef.current === -1) {
                    xMoveRef.current = 0
                    yMoveRef.current = -1
                }
                if (score === 2 && xBallRef.current === 4 && yBallRef.current === 10 && yBallRef.current < move - 1) {
                    xMoveRef.current = -1
                    yMoveRef.current = 0
                }
                if (score === 2 && xBallRef.current === 4 && yBallRef.current === 35 && yBallRef.current > move + 11) {
                    xMoveRef.current = -1
                    yMoveRef.current = 0
                }
                if (xBallRef.current === 1) {
                    if (yBallRef.current > move - 1 && yBallRef.current < move + 11) {
                        xMoveRef.current = 1
                        setScore(score + 1)

                    }
                }


                xBallRef.current = xBallRef.current + xMoveRef.current
                yBallRef.current = yBallRef.current + yMoveRef.current

            }, 50)
            return () => clearInterval(interval)
        }
    }, [moving, seconds, move, score])

    const clearBall = () => {
        setMoving(false)
        xBallRef.current = 30
        yBallRef.current = 20
        xMoveRef.current = 1
        yMoveRef.current = -1
        setSeconds(0)
        setMove(25)
        setScore(0)
        setMoving(true)
    }

    return (
        <div>
            {/* <div className='play-box'> */}
            <div>
                <svg viewBox='-1 -15 100 75'>
                    <rect x='0' y='0' width="99" height="60"
                        fill="lightgrey" stroke="black"
                        strokeWidth="0.5" />

                    <motion.circle
                        animate={{ x: xBallRef.current, y: yBallRef.current }}
                        x='10' y='10' r='0.7'
                        transition={{ ease: "easeOut", duration: 0.3 }}>
                    </motion.circle>

                    <motion.rect x='0' y='0' width='1' height='9'
                        animate={{ y: move, x: 1 }}
                        transition={{ ease: "easeOut", duration: 1 }}>
                    </motion.rect>
                </svg>
            </div>
        </div>
    )
}
