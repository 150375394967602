import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { SVGCard } from './components/SVGCard';
import { CheckV } from './components/CheckV';
import { Circle } from './components/Circle';
import { Clock } from './components/Clock';
import { QRCode } from './components/QRCode';
import { LastRow } from './components/LastRow';
import { Clues } from './components/Clues';
import { Cross } from './components/Cross';
import { Lock } from './components/Lock';
import { OnOff } from './components/OnOff';
import { Timer } from './components/Timer';
import { CircleBadge } from './components/CircleBadge';
import { Lines } from './components/Lines';
import { Rectangle } from './components/Rectangle';
import { CardSpielchen } from './components/CardSpielchen';
import { CardBus } from './components/CardBus';
import { BusButton } from './components/BusButton';

function App() {
  return (
    <>
      <Container>
        {/* <div className="App">
          <header className="App-header">Header</header>
        </div> */}
        <Row >
          <Col sm><SVGCard /></Col>
        </Row>
        <Row style={{ height: '10px' }}>
        </Row>
        <Row>
          <Col ><Clock /></Col>
          <Col > <Timer /></Col>
          <Col ><CheckV /></Col>
          <Col ><QRCode /></Col>
        </Row>
        <Row>
          <Col><Clues /> </Col>
          <Col><Cross /> </Col>
          <Col ><Circle /> </Col>
          <Col><OnOff /> </Col>
        </Row>
        <Row>
          <Col><CircleBadge /> </Col>
          <Col><Lines /> </Col>
          <Col><Lock /> </Col>
          <Col><Rectangle /> </Col>
        </Row>
        <Row>
          <Col><CardSpielchen /></Col>
          <Col><BusButton /></Col>
        </Row>
        <Row>
          <Col><CardBus /> </Col>
        </Row>        
        <Row>
          <LastRow />
        </Row>
      </Container>
    </>
  );
}

export default App;
