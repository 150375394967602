import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

export function QRCode() {
    const [isMove, setIsMove] = useState(true);
    const controls = useAnimation()

    let width = 110
    let height = 110

    const variants = {
        initial: {
            pathLength: 1,
            opacity: 1
        }
    }

    useEffect(() => {
        if (isMove) {
            controls.start({
                opacity: 1,
            })
            controls.start({
                pathLength: [0, 1],
                transition: { duration: 9 },
            })
        } else {
            controls.start({
                pathLength: 0,
                transition: { duration: 1, delay: 0 },
            })
            controls.start({
                opacity: 0, transition: { delay: 1 }
            })
        }
    }, [isMove, controls])

    return (
        <div>
            <Card className='card-sm'>
                <Card.Body>
                    <Card.Title>QR Code</Card.Title>
                    <Card.Text>
                        <span className="circle-line-rect">
                            <svg
                                viewBox="-2 -4 20 20"
                                width={width} height={height}
                            >
                                <motion.path
                                    d="M14 12 14 11 13 11 13 12 14 12M13 11 13 10 12 10 12 11 13 11M14 10 12 10 12 9 14 9 14 10 14 9M14 9 14 8 13 8 13 9 14 9 14 9M12 8 12 7 13 7 13 8 12 8 12 8M14 7 14 6 12 6 12 7 14 7M14 5 14 6 13 6 13 5 14 5M14 4 14 0 10 0 10 4 14 4M11 1 13 1 13 3 11 3 11 1M0 0 0 0 4 0 4 4 0 4 0 0M1 1 1 3 3 3 3 1 1 1M0 12 4 12 4 8 0 8 0 12 0 12M1 9 3 9 3 11 1 11 1 9M11 5 12 5 12 6 10 6 10 5 11 5M11 7 11 8 10 8 10 7 11 7 11 7M11 9 11 10 10 10 10 9 11 9 11 9M11 12 10 12 10 11 11 11 11 12M9 12 9 11 8 11 8 12 9 12M9 10 9 8 8 8 8 10 9 10M9 7 9 6 8 6 8 7 9 7M8 6 8 6 8 5 10 5 10 6 8 6M8 4 9 4 9 5 8 5 8 4 8 4M9 3 9 1 8 1 8 3 9 3M7 1 7 0 6 0 6 1 7 1M7 2 5 2 5 1 7 1 7 2M6 3 8 3 8 2 6 2 6 3M5 4 5 3 6 3 6 4 5 4M7 5 7 4 6 4 6 6 7 6 7 5M7 7 7 8 6 8 6 7 7 7M7 9 7 10 6 10 6 9 7 9M7 11 7 12 5 12 5 11 7 11M5 8 6 8 6 9 5 9 5 8M4 7 5 7 5 5 4 5 4 7M3 5 3 6 2 6 2 5 3 5 3 5M1 5 0 5 0 7 1 7 1 5M2 7 2 6 1 6 1 7 2 7"
                                    strokeWidth="1%"
                                    stroke="black"
                                    fill='lightgrey'
                                    //strokeLinecap="round"
                                    variants={variants}
                                    initial='initial'
                                    animate={controls}
                                />
                            </svg>
                        </span>
                    </Card.Text>

                </Card.Body>
                <Button onClick={() => setIsMove(!isMove)}>Animation</Button>
            </Card>
        </div>
    )
}
