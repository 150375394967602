import React, { useState, useEffect } from 'react'
import { motion, useAnimation } from 'framer-motion'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import buttonRedDark from '../graphics/buttonRedDark.png';
import buttonRedLight from '../graphics/buttonRedLight.png';

export function BusButton() {
    const controls1 = useAnimation()
    const controls2 = useAnimation()
    const [isMove, setIsMove] = useState(false);
    const duration = 1

    const variants1 = {
        initial: {
            opacity: 1,
        }
    }
    useEffect(() => {
        if (isMove) {
            controls1.start({
                y: [0, 2],
                scale: [1, 0.95],
                opacity: [1, 0],
                transition: { duration: duration, delay: 0.3 }
            })
        } else {
            controls1.start({
                y: [2, 0],
                scale: [0.95, 1],
                opacity: [0, 1],
                transition: { duration: duration }
            })
        }
    }, [isMove, controls1])

    const variants2 = {
        initial: {
            scale: 0.95,
            opacity: 0,
        }
    }
    useEffect(() => {
        if (isMove) {
            controls2.start({
                scale: [1, 0.95],
                opacity: [0, 1],
                transition: { duration: duration }
            })
        } else {
            controls2.start({
                scale: [0.95, 1],
                opacity: [1, 0],
                transition: { duration: duration, delay: 0.3 }
            })
        }
    }, [isMove, controls2])

    return (
        <>
            <Card className="card-sm">
                <Card.Body>
                    <Card.Title className='card-md'
                    >Button</Card.Title>
                    <Card.Text className='buttons'>
                        <motion.img src={buttonRedDark} alt='img'
                            variants={variants1}
                            initial='initial'
                            animate={controls1}
                        />
                        <motion.img src={buttonRedLight} alt='img'
                            variants={variants2}
                            initial='initial'
                            animate={controls2}
                        />
                        <br /><br />
                    </Card.Text>
                </Card.Body>
                <Button onClick={() => setIsMove(state => !state)}>Animation</Button>
            </Card>
        </>
    )
}
