const digits6Col = {
    digits6: [
        // zero
        'M 162.4 15.6 L 180 15.6 L 176.8 18.8 L 165.6 18.8 L 162.4 15.6 M 177.6 19.6 L 180.8 16.4 L 180.8 31.6 L 179.2 31.6 L 177.6 30 L 177.6 19.6 M 161.6 16.4 L 164.8 19.6 L 164.8 30 L 163.2 31.6 L 161.6 31.6 L 161.6 16.4 M 180.8 33.2 L 180.8 48.4 L 177.6 45.2 L 177.6 34.8 L 179.2 33.2 L 180.8 33.2 M 161.6 33.2 L 163.2 33.2 L 164.8 34.8 L 164.8 45.2 L 161.6 48.4 L 161.6 33.2 M 165.6 46 L 176.8 46 L 180 49.2 L 162.4 49.2 L 165.6 46',
        // one
        'M 162.4 15.6 M 177.6 19.6 L 180.8 16.4 L 180.8 31.6 L 179.2 31.6 L 177.6 30 L 177.6 19.6 M 161.6 16.4 L 161.6 16.4 M 180.8 33.2 L 180.8 48.4 L 177.6 45.2 L 177.6 34.8 L 179.2 33.2 L 180.8 33.2',
        // two
        'M 162.4 15.6 L 180 15.6 L 176.8 18.8 L 165.6 18.8 L 162.4 15.6 M 177.6 19.6 L 180.8 16.4 L 180.8 31.6 L 179.2 31.6 L 177.6 30 L 177.6 19.6 M 178.4 32.4 L 176.8 34 L 165.6 34 L 164 32.4 L 165.6 30.8 L 176.8 30.8 L 178.4 32.4 M 161.6 33.2 L 163.2 33.2 L 164.8 34.8 L 164.8 45.2 L 161.6 48.4 L 161.6 33.2 M 165.6 46 L 176.8 46 L 180 49.2 L 162.4 49.2 L 165.6 46',
        // three
        'M 162.4 15.6 L 180 15.6 L 176.8 18.8 L 165.6 18.8 L 162.4 15.6 M 177.6 19.6 L 180.8 16.4 L 180.8 31.6 L 179.2 31.6 L 177.6 30 L 177.6 19.6 M 178.4 32.4 L 176.8 34 L 165.6 34 L 164 32.4 L 165.6 30.8 L 176.8 30.8 L 178.4 32.4 M 180.8 33.2 L 180.8 48.4 L 177.6 45.2 L 177.6 34.8 L 179.2 33.2 L 180.8 33.2 M 165.6 46 L 176.8 46 L 180 49.2 L 162.4 49.2 L 165.6 46',
        // four
        'M 165 20 M 177.6 19.6 L 180.8 16.4 L 180.8 31.6 L 179.2 31.6 L 177.6 30 L 177.6 19.6 M 178.4 32.4 L 176.8 34 L 165.6 34 L 164 32.4 L 165.6 30.8 L 176.8 30.8 L 178.4 32.4 M 161.6 16.4 L 164.8 19.6 L 164.8 30 L 163.2 31.6 L 161.6 31.6 L 161.6 16.4 M 180.8 33.2 L 180.8 48.4 L 177.6 45.2 L 177.6 34.8 L 179.2 33.2 L 180.8 33.2',
        // five
        'M 162.4 15.6 L 180 15.6 L 176.8 18.8 L 165.6 18.8 L 162.4 15.6 M 178.4 32.4 L 176.8 34 L 165.6 34 L 164 32.4 L 165.6 30.8 L 176.8 30.8 L 178.4 32.4 M 161.6 16.4 L 164.8 19.6 L 164.8 30 L 163.2 31.6 L 161.6 31.6 L 161.6 16.4 M 180.8 33.2 L 180.8 48.4 L 177.6 45.2 L 177.6 34.8 L 179.2 33.2 L 180.8 33.2 M 165.6 46 L 176.8 46 L 180 49.2 L 162.4 49.2 L 165.6 46',
        // six
        'M 162.4 15.6 L 180 15.6 L 176.8 18.8 L 165.6 18.8 L 162.4 15.6 M 178.4 32.4 L 176.8 34 L 165.6 34 L 164 32.4 L 165.6 30.8 L 176.8 30.8 L 178.4 32.4 M 161.6 16.4 L 164.8 19.6 L 164.8 30 L 163.2 31.6 L 161.6 31.6 L 161.6 16.4 M 180.8 33.2 L 180.8 48.4 L 177.6 45.2 L 177.6 34.8 L 179.2 33.2 L 180.8 33.2 M 161.6 33.2 L 163.2 33.2 L 164.8 34.8 L 164.8 45.2 L 161.6 48.4 L 161.6 33.2 M 165.6 46 L 176.8 46 L 180 49.2 L 162.4 49.2 L 165.6 46',
        // seven
        'M 162.4 15.6 L 180 15.6 L 176.8 18.8 L 165.6 18.8 L 162.4 15.6 M 177.6 19.6 L 180.8 16.4 L 180.8 31.6 L 179.2 31.6 L 177.6 30 L 177.6 19.6 M 161.6 16.4 L 161.6 16.4 M 180.8 33.2 L 180.8 48.4 L 177.6 45.2 L 177.6 34.8 L 179.2 33.2 L 180.8 33.2',
        // eight
        'M 162.4 15.6 L 180 15.6 L 176.8 18.8 L 165.6 18.8 L 162.4 15.6 M 177.6 19.6 L 180.8 16.4 L 180.8 31.6 L 179.2 31.6 L 177.6 30 L 177.6 19.6 M 178.4 32.4 L 176.8 34 L 165.6 34 L 164 32.4 L 165.6 30.8 L 176.8 30.8 L 178.4 32.4 M 161.6 16.4 L 164.8 19.6 L 164.8 30 L 163.2 31.6 L 161.6 31.6 L 161.6 16.4 M 180.8 33.2 L 180.8 48.4 L 177.6 45.2 L 177.6 34.8 L 179.2 33.2 L 180.8 33.2 M 161.6 33.2 L 163.2 33.2 L 164.8 34.8 L 164.8 45.2 L 161.6 48.4 L 161.6 33.2 M 165.6 46 L 176.8 46 L 180 49.2 L 162.4 49.2 L 165.6 46',
        // nine
        'M 162.4 15.6 L 180 15.6 L 176.8 18.8 L 165.6 18.8 L 162.4 15.6 M 177.6 19.6 L 180.8 16.4 L 180.8 31.6 L 179.2 31.6 L 177.6 30 L 177.6 19.6 M 178.4 32.4 L 176.8 34 L 165.6 34 L 164 32.4 L 165.6 30.8 L 176.8 30.8 L 178.4 32.4 M 161.6 16.4 L 164.8 19.6 L 164.8 30 L 163.2 31.6 L 161.6 31.6 L 161.6 16.4 M 180.8 33.2 L 180.8 48.4 L 177.6 45.2 L 177.6 34.8 L 179.2 33.2 L 180.8 33.2 M 165.6 46 L 176.8 46 L 180 49.2 L 162.4 49.2 L 165.6 46',
    ]
}
export default digits6Col