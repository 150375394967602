const digits1Col = {
    digits1: [
        // zero
        'M 8 7 L 30 7 L 26 11 L 12 11 L 8 7 M 27 12 L 31 8 L 31 27 L 29 27 L 27 25 L 27 12 M 7 8 L 11 12 L 11 25 L 9 27 L 7 27 L 7 8 M 31 29 L 31 48 L 27 44 L 27 31 L 29 29 L 31 29 M 7 29 L 9 29 L 11 31 L 11 44 L 7 48 L 7 29 M 12 45 L 26 45 L 30 49 L 8 49 L 12 45',
        // one
        'M 8 7 M 27 12 L 31 8 L 31 27 L 29 27 L 27 25 L 27 12 M 31 29 L 31 48 L 27 44 L 27 31 L 29 29 L 31 29',
        // two
        'M 8 7 L 30 7 L 26 11 L 12 11 L 8 7 M 27 12 L 31 8 L 31 27 L 29 27 L 27 25 L 27 12 M 28 28 L 26 30 L 12 30 L 10 28 L 12 26 L 26 26 L 28 28 M 7 29 L 9 29 L 11 31 L 11 44 L 7 48 L 7 29 M 12 45 L 26 45 L 30 49 L 8 49 L 12 45',
        // three
        'M 8 7 L 30 7 L 26 11 L 12 11 L 8 7 M 27 12 L 31 8 L 31 27 L 29 27 L 27 25 L 27 12 M 28 28 L 26 30 L 12 30 L 10 28 L 12 26 L 26 26 L 28 28 M 31 29 L 31 48 L 27 44 L 27 31 L 29 29 L 31 29 M 12 45 L 26 45 L 30 49 L 8 49 L 12 45',
        // four
        'M 8 7 M 27 12 L 31 8 L 31 27 L 29 27 L 27 25 L 27 12 M 28 28 L 26 30 L 12 30 L 10 28 L 12 26 L 26 26 L 28 28 M 7 8 L 11 12 L 11 25 L 9 27 L 7 27 L 7 8 M 31 29 L 31 48 L 27 44 L 27 31 L 29 29 L 31 29',
        // five
        'M 8 7 L 30 7 L 26 11 L 12 11 L 8 7 M 28 28 L 26 30 L 12 30 L 10 28 L 12 26 L 26 26 L 28 28 M 7 8 L 11 12 L 11 25 L 9 27 L 7 27 L 7 8 M 31 29 L 31 48 L 27 44 L 27 31 L 29 29 L 31 29 M 12 45 L 26 45 L 30 49 L 8 49 L 12 45',
        // six
        'M 8 7 L 30 7 L 26 11 L 12 11 L 8 7 M 28 28 L 26 30 L 12 30 L 10 28 L 12 26 L 26 26 L 28 28 M 7 8 L 11 12 L 11 25 L 9 27 L 7 27 L 7 8 M 31 29 L 31 48 L 27 44 L 27 31 L 29 29 L 31 29 M 7 29 L 9 29 L 11 31 L 11 44 L 7 48 L 7 29 M 12 45 L 26 45 L 30 49 L 8 49 L 12 45',
        // seven
        'M 8 7 L 30 7 L 26 11 L 12 11 L 8 7 M 27 12 L 31 8 L 31 27 L 29 27 L 27 25 L 27 12 M 7 8 L 7 8 M 31 29 L 31 48 L 27 44 L 27 31 L 29 29 L 31 29',
        // eight
        'M8 7 30 7 26 11 12 11 8 7 M27 12 31 8 31 27 29 27 27 25 27 12 M28 28 26 30 12 30 10 28 12 26 26 26 28 28 M7 8 11 12 11 25 9 27 7 27 7 8 M31 29 31 48 27 44 27 31 29 29 31 29 M7 29 9 29 11 31 11 44 7 48 7 29 M12 45 26 45 30 49 8 49 12 45',
        // nine
        'M 8 7 L 30 7 L 26 11 L 12 11 L 8 7 M 27 12 L 31 8 L 31 27 L 29 27 L 27 25 L 27 12 M 28 28 L 26 30 L 12 30 L 10 28 L 12 26 L 26 26 L 28 28 M 7 8 L 11 12 L 11 25 L 9 27 L 7 27 L 7 8 M 31 29 L 31 48 L 27 44 L 27 31 L 29 29 L 31 29 M 12 45 L 26 45 L 30 49 L 8 49 L 12 45',
    ]
}
export default digits1Col