import React, { useState } from 'react'
import { motion } from 'framer-motion'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

export function OnOff() {

    const iconVariants = {
        closed: { opacity: 0, transition: { duration: 1 } },
        opened: { opacity: 1, transition: { duration: 1 } },
    }
    // Checks the status of the top menu being pulled out
    const [isOpen, setIsOpen] = useState(false)


    return (
        <div>
            <Card className='card-sm'>
                <Card.Body>
                    <Card.Title>ON / OFF</Card.Title>
                    <Card.Text>
                        <svg
                            viewBox='-10 -15 40 40'
                            width="100px"
                            height="100px"
                        >
                            <path
                                //d='M6 3C0 4.5 0 18 10.5 18 21 18 21 4.5 15 3L15 0C25.5 1.5 25.5 21 10.5 21-4.5 21-4.5 1.5 6 0L6 3 9 1.5 9 1.5 12 1.5 12-3C12-4.5 9-4.5 9-3L9 1.5 12 1.5 12 9C12 10.5 9 10.5 9 9L9 1.5'
                                d='M 6 3 C 0 4.5 0 18 10.5 18 C 21 18 21 4.5 15 3 L 15 0 C 25.5 1.5 25.5 21 10.5 21 C -4.5 21 -4.5 1.5 6 0 L 6 3 L 6 3 M 9 8 C 9 9 12 9 12 8 L 12 -4 C 12 -5 9 -5 9 -4 L 9 8'
                                fill="red"
                                stroke='rgba(250, 0, 0, 0.6)'
                                strokeWidth='0.7'
                                strokeLinecap="round"
                            />
                            <motion.path
                                initial='opened'
                                variants={iconVariants}
                                animate={isOpen ? 'opened' : 'closed'}
                                //stroke="#ff0055"
                                //d='M6 3C0 4.5 0 18 10.5 18 21 18 21 4.5 15 3L15 0C25.5 1.5 25.5 21 10.5 21-4.5 21-4.5 1.5 6 0L6 3 9 1.5 9 1.5 12 1.5 12-3C12-4.5 9-4.5 9-3L9 1.5 12 1.5 12 9C12 10.5 9 10.5 9 9L9 1.5'
                                d='M 6 3 C 0 4.5 0 18 10.5 18 C 21 18 21 4.5 15 3 L 15 0 C 25.5 1.5 25.5 21 10.5 21 C -4.5 21 -4.5 1.5 6 0 L 6 3 L 6 3 M 9 8 C 9 9 12 9 12 8 L 12 -4 C 12 -5 9 -5 9 -4 L 9 8'
                                fill="green"
                                stroke='lightGreen'
                                strokeWidth='1.2'
                                strokeLinecap="round"
                            />

                        </svg>


                    </Card.Text>

                </Card.Body>
                <Button onClick={() => setIsOpen(value => !value)} >Animation</Button>
            </Card>
        </div>
    )
}
