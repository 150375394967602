import React, { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

export function Rectangle() {
    const controlsThin = useAnimation();
    const controlsBold = useAnimation();
    const [isMove, setIsMove] = useState(true);
    let width = 100
    let height = 100

    const variantsThin = {
        initial: {
            pathLenght: 1,
            opacity: 1,
        }
    }
    const variantsBold = {
        initial: {
            pathLenght: 1,
            opacity: 1,
        }
    }

    // OUTER RECTANGLE
    useEffect(() => {
        if (isMove) {
            controlsThin.start({
                opacity: 1,
            })
            controlsThin.start({
                pathLength: [1, 0, 1],
                transition: { duration: 2 },
            })
        } else {
            controlsThin.start({
                pathLength: 0,
                transition: { duration: 2 },
            })
            controlsThin.start({
                opacity: 0, transition: { delay: 2 }
            })
        }
    }, [isMove, controlsThin])

    // INNER RECTANGLE
    useEffect(() => {
        if (isMove) {
            controlsBold.start({
                opacity: 1,
            })
            controlsBold.start({
                pathLength: [0, 1, 0, 1],
                transition: { duration: 4 },
            })
        } else {
            controlsBold.start({
                pathLength: 0,
                transition: { duration: 2 },
            })
            controlsBold.start({
                opacity: 0, transition: { delay: 2 }
            })
        }
    }, [isMove, controlsBold])

    return (
        <div>
            <Card className='card-sm'>
                <Card.Body>
                    <Card.Title>Rect Badge</Card.Title>
                    <Card.Text>
                        <svg
                            viewBox="-12 -37 160 160"
                            width={width} height={height}>
                            {/* Letters CA */}
                            <motion.path
                                d="M 54 66 M 59 25 C 53 19 46 25 46 32 C 46 38 51 46 59 40 M 65 42 L 72 23 L 79 42 L 76 34 L 68 34 L 68 34"
                                fill="transparent"
                                strokeWidth="3"
                                stroke="green"
                                strokeLinecap="round"
                                variants={variantsThin}
                                initial='initial'
                                animate={controlsThin}
                            />


                            {/* Rectangle thin line */}
                            <motion.path
                                d="M 111.27 9.5 C 116.13 9.5 115.32 55.67 111.27 55.67 C 111.27 58.91 16.5 58.91 16.5 55.67 C 12.45 55.67 12.45 9.5 16.5 9.5 C 16.5 4.64 111.27 5.45 111.27 9.5"
                                fill="transparent"
                                strokeWidth="2"
                                stroke="lightGreen"
                                strokeLinecap="round"
                                variants={variantsThin}
                                initial='initial'
                                animate={controlsThin}
                            />
                            <svg>
                                {/* Rectangle bold line */}
                                <motion.path
                                    d="M 120 5 C 128 5 127 62 122 62 C 122 66 5 66 5 62 C 0 62 0 5 5 5 C 5 -1 122 0 122 5"
                                    fill="transparent"
                                    strokeWidth="5"
                                    stroke="lightGreen"
                                    strokeLinecap="round"
                                    variants={variantsBold}
                                    initial='initial'
                                    animate={controlsBold}
                                />
                            </svg>
                        </svg>
                    </Card.Text>

                </Card.Body>
                <Button onClick={() => setIsMove(!isMove)}>Animation</Button>
            </Card>
        </div>
    )
}
