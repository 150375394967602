import React, { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

export function CircleBadge() {
    const controls = useAnimation();
    const controlsText = useAnimation();
    const [isMove, setIsMove] = useState(true);
    let width = 100
    let height = 100

    // EFFECT FOR LETTER IN A CIRCLE
    const variantsText = {
        initial: {
            pathLenght: 1,
            opacity: 1,
        }
    }
    useEffect(() => {
        if (isMove) {
            controlsText.start({
                opacity: 1,
            })
            controlsText.start({
                pathLength: 1,
                transition: { duration: 2 },
            })
        } else {
            controlsText.start({
                pathLength: 0,
                transition: { duration: 2, delay: 2 },
            })
            controlsText.start({
                opacity: 0, transition: { delay: 2 }
            })
        }
    }, [isMove, controlsText])


    // EFFECT FOR CICRCLES
    const variants = {
        initial: {
            pathLenght: 1,
            opacity: 1,
        }
    }

    useEffect(() => {
        if (isMove) {
            controls.start({
                opacity: 1,
            })
            controls.start({
                pathLength: 0.8,
                transition: { duration: 2 },
            })
        } else {
            controls.start({
                pathLength: 0,
                transition: { duration: 2 },
            })
            controls.start({
                opacity: 0, transition: { delay: 2 }
            })
        }
    }, [isMove, controls])

    return (
        <div>
            <Card className='card-sm'>
                <Card.Body>
                    <Card.Title>Circle Badge</Card.Title>
                    <Card.Text>
                        <svg
                            viewBox="0 0 100 100"
                            width={width} height={height}>
                            {/* Letter B in a circle*/}
                            <svg>
                                <motion.path
                                    d='M 51 73 M 47 30 L 39 59 C 43 59 47 59 51 59 C 59 59 66 42 51 42 C 61 42 62 30 54 30 L 47 30'
                                    fill="transparent"
                                    strokeWidth="1"
                                    stroke="green"
                                    strokeLinecap="round"
                                    variants={variantsText}
                                    initial='initial'
                                    animate={controlsText}
                                />
                            </svg>
                            {/* OUTER CIRCLE */}
                            <motion.path
                                d="M 50 10 C 100 10 100 80 50 80 C 0 80 0 10 50 10"
                                fill="transparent"
                                strokeWidth="1"
                                stroke="lightGreen"
                                strokeLinecap="round"
                                variants={variants}
                                initial='initial'
                                animate={controls}
                            />

                            {/* SMALL CIRCLE */}
                            <motion.path
                                //d="M 49 13 C 94 13 94 76 49 76 C 4 76 4 13 49 13"
                                d='M 49 76 C 93 76 93 14 50 14 C 7 14 7 76 49 76'
                                fill="transparent"
                                strokeWidth="1"
                                stroke="lightGreen"
                                strokeLinecap="round"
                                variants={variants}
                                initial='initial'
                                animate={controls}
                            />

                        </svg>

                    </Card.Text>
                </Card.Body>
                <Button onClick={() => setIsMove(!isMove)}>Animation</Button>
            </Card>
        </div>
    )
}
