import React, { useEffect, useRef, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Digits1Col from "./digits/Digits1Col";
import Digits2Col from "./digits/Digits2Col";
import Digits3Col from "./digits/Digits3Col";
import Digits4Col from "./digits/Digits4Col";
import Digits5Col from "./digits/Digits5Col";
import Digits6Col from "./digits/Digits6Col";

export function Timer() {
    const [isMove, setIsMove] = useState(true);
    const controls = useAnimation()

    const digits1Arr = Digits1Col.digits1;
    const [digitOneCol, setDigitOneCol] = useState(digits1Arr[0])
    const digits2Arr = Digits2Col.digits2;
    const [digitOTwoCol, setDigitTwoCol] = useState(digits2Arr[0])
    const digits3Arr = Digits3Col.digits3;
    const [digitThreeCol, setDigitThreeCol] = useState(digits3Arr[0])
    const digits4Arr = Digits4Col.digits4;
    const [digitFourCol, setDigitFourCol] = useState(digits4Arr[0])
    const digits5Arr = Digits5Col.digits5;
    const [digitFiveCol, setDigitFiveCol] = useState(digits5Arr[0])
    const digits6Arr = Digits6Col.digits6
    const [digitSixCol, setDigitSixCol] = useState(digits6Arr[0])

    const indexRef1 = useRef(0)
    const indexRef2 = useRef(0)
    const indexRef3 = useRef(0)
    const indexRef4 = useRef(0)
    const indexRef5 = useRef(0)
    const indexRef6 = useRef(0)

    const [go, setGo] = useState(true)

    useEffect(() => {
        if (go) {
            const timerDigits = setInterval(() => {
                indexRef6.current = indexRef6.current + 1;
                if (indexRef6.current > digits6Arr.length - 1) {
                    indexRef6.current = 0;
                    indexRef5.current = indexRef5.current + 1;                    
                    if (indexRef5.current > digits5Arr.length - 1) {
                        indexRef5.current = 0;
                        indexRef4.current = indexRef4.current + 1;
                        if(indexRef4.current > 9) {
                            indexRef4.current = 0
                        }
                    }                    
                }
                setDigitSixCol(digits6Arr[indexRef6.current]);
                setDigitFiveCol(digits5Arr[indexRef5.current]);
                setDigitFourCol(digits4Arr[indexRef4.current]);
                setDigitThreeCol(digits3Arr[indexRef3.current]);
                setDigitTwoCol(digits2Arr[indexRef2.current]);
                setDigitOneCol(digits1Arr[indexRef1.current]);

            }, 100);
            return () => {
                clearInterval(timerDigits);
            };
        }

    });

    // function updateDigit5() {
    //     return digits5Arr[indexRef5.current];
    // }
    // function updateDigit6() {
    //     return digits6Arr[indexRef6.current];
    // }

    let width = 120
    let height = 40

    const variants = {
        initial: {
            opacity: 1
        }
    }

    useEffect(() => {
        if (isMove) {
            controls.start(
                { opacity: 1 }
            )
        } else {
            controls.start({
                transition: { duration: 0 },
            })
            controls.start({
                opacity: [0.5, 0.8, 0.2, 0], transition: { delay: 0 }
            })
        }
    }, [isMove, controls])

    // useEffect(() => {
    //     if (isMove) {
    //         controls.start(i => ({
    //             //opacity: [ 1, 0, 0.5, 0.8, 0.2, 1, 0.6],
    //             opacity: [1, 0.7, 0.2, 1],
    //             transition: { delay: i * 2, duration: 0, repeat: 2 },
    //         })
    //         )
    //     } else {
    //         controls.start({
    //             transition: { duration: 0 },
    //         })
    //         controls.start({
    //             opacity: [0.5, 0.8, 0.2, 0], transition: { delay: 0 }
    //         })
    //     }
    // }, [isMove, controls])


    return (
        <>
            <Card className="card-sm">

                <Card.Body>
                    <Card.Title>Timer </Card.Title>
                    <Card.Text>
                        {/* <motion.div
                            variants={variants}
                            initial='initial'
                            animate={controls}
                            custom={0}>
                            <Digit0 />
                        </motion.div> */}


                        <span className="circle-line-rect">

                            <motion.svg
                                viewBox="63 0 65 65"
                                width={width} height={height}
                            >

                                {/* RECTANGLE Backgroudn */}
                                <path
                                    //d="M2 2 26 2 26 15 2 15 2 2 2 2"
                                    //d="M0 0 133 0 133 56 0 56 0 0 0 0"
                                    d='
                                    M 0 0 L 187 0 L 187 56 L 0 56 L 0 0 L 0 0
                                    '
                                    fill="black"
                                    strokeWidth="3"
                                    stroke="darkgrey"
                                    strokeLinecap="round"

                                />

                                {/* First 8 on Clock */}
                                <motion.path
                                    d={digitOneCol}
                                    fill="red"
                                    variants={variants}
                                    initial='initial'
                                    animate={controls}
                                    custom={0}
                                />

                                {/* Second 8 on Clock */}
                                <motion.path
                                    d={digitOTwoCol}
                                    //             d="
                                    // M38 7 60 7 56 11 42 11 38 7
                                    // M57 12 61 8 61 27 59 27 57 25 57 12
                                    // M58 28 56 30 42 30 40 28 42 26 56 26 58 28
                                    // M37 8 41 12 41 25 39 27 37 27 37 8
                                    // M61 29 61 48 57 44 57 31 59 29 61 29
                                    // M37 29 39 29 41 31 41 44 37 48 37 29
                                    // M42 45 56 45 60 49 38 49 42 45
                                    // "
                                    fill="red"
                                    variants={variants}
                                    initial='initial'
                                    animate={controls}
                                    custom={1}
                                />

                                {/* points between 88:88 */}
                                <motion.path
                                    d="
                        M65 18 68 18 68 21 65 21 65 18M65 35 68 35 68 38 65 38 65 35
                        "
                                    fill="red"
                                    variants={variants}
                                    initial='initial'
                                    animate={controls}
                                    custom={2}
                                />

                                {/* Third 8 on Clock */}
                                <motion.path
                                    d={digitThreeCol}
                                    //             d="
                                    //             M73 7 95 7 91 11 77 11 73 7
                                    // M92 12 96 8 96 27 94 27 92 25 92 12
                                    // M93 28 91 30 77 30 75 28 77 26 91 26 93 28
                                    // M72 8 76 12 76 25 74 27 72 27 72 8
                                    // M96 29 96 48 92 44 92 31 94 29 96 29
                                    // M72 29 74 29 76 31 76 44 72 48 72 29
                                    // M77 45 91 45 95 49 73 49 77 45
                                    // "

                                    //d="M73 7 95 7 91 11 77 11 73 7   M93 28 91 30 77 30 75 28 77 26 91 26 93 28M72 8 76 12 76 25 74 27 72 27 72 8M96 29 96 48 92 44 92 31 94 29 96 29M72 29 74 29 76 31 76 44 72 48 72 29M77 45 91 45 95 49 73 49 77 45M103 7 125 7 121 11 107 11 103 7M122 12 126 8 126 27 124 27 122 25 122 12M123 28 121 30 107 30 105 28 107 26 121 26 123 28M102 8 106 12 106 25 104 27 102 27 102 8M126 29 126 48 122 44 122 31 124 29 126 29M102 29 104 29 106 31 106 44 102 48 102 29M107 45 121 45 125 49 103 49 107 45"
                                    fill="red"
                                    variants={variants}
                                    initial='initial'
                                    animate={controls}
                                    custom={3}
                                />

                                {/* Fourth 8 on Clock */}
                                <motion.path
                                    d={digitFourCol}
                                    //             d="
                                    // M103 7 125 7 121 11 107 11 103 7
                                    // M122 12 126 8 126 27 124 27 122 25 122 12
                                    // M123 28 121 30 107 30 105 28 107 26 121 26 123 28
                                    // M102 8 106 12 106 25 104 27 102 27 102 8
                                    // M126 29 126 48 122 44 122 31 124 29 126 29
                                    // M102 29 104 29 106 31 106 44 102 48 102 29
                                    // M107 45 121 45 125 49 103 49 107 45
                                    // "

                                    //d="M73 7 95 7 91 11 77 11 73 7   M93 28 91 30 77 30 75 28 77 26 91 26 93 28M72 8 76 12 76 25 74 27 72 27 72 8M96 29 96 48 92 44 92 31 94 29 96 29M72 29 74 29 76 31 76 44 72 48 72 29M77 45 91 45 95 49 73 49 77 45M103 7 125 7 121 11 107 11 103 7M122 12 126 8 126 27 124 27 122 25 122 12M123 28 121 30 107 30 105 28 107 26 121 26 123 28M102 8 106 12 106 25 104 27 102 27 102 8M126 29 126 48 122 44 122 31 124 29 126 29M102 29 104 29 106 31 106 44 102 48 102 29M107 45 121 45 125 49 103 49 107 45"
                                    fill="red"
                                    variants={variants}
                                    initial='initial'
                                    animate={controls}
                                    custom={4}
                                />

                                {/* Second points between 88:88 */}
                                <motion.path
                                    // d='M65 18 68 18 68 21 65 21 65 18M65 35 68 35 68 38 65 38 65 35'
                                    d="                        
                        M 130 24 L 133 24 L 133 27 L 130 27 L 130 24 M 130 37 L 133 37 L 133 40 L 130 40 L 130 37
                        "
                                    fill="red"
                                    variants={variants}
                                    initial='initial'
                                    animate={controls}
                                    custom={2}
                                />

                                {/* Fifth 8 on Clock */}
                                <motion.path
                                    d={digitFiveCol}
                                    // d="
                                    // M 137.4 15.6 L 155 15.6 L 151.8 18.8 L 140.6 18.8 L 137.4 15.6 M 152.6 19.6 L 155.8 16.4 L 155.8 31.6 L 154.2 31.6 L 152.6 30 L 152.6 19.6 M 153.4 32.4 L 151.8 34 L 140.6 34 L 139 32.4 L 140.6 30.8 L 151.8 30.8 L 153.4 32.4 M 136.6 16.4 L 139.8 19.6 L 139.8 30 L 138.2 31.6 L 136.6 31.6 L 136.6 16.4 M 155.8 33.2 L 155.8 48.4 L 152.6 45.2 L 152.6 34.8 L 154.2 33.2 L 155.8 33.2 M 136.6 33.2 L 138.2 33.2 L 139.8 34.8 L 139.8 45.2 L 136.6 48.4 L 136.6 33.2 M 140.6 46 L 151.8 46 L 155 49.2 L 137.4 49.2 L 140.6 46
                                    // "

                                    //d="M73 7 95 7 91 11 77 11 73 7   M93 28 91 30 77 30 75 28 77 26 91 26 93 28M72 8 76 12 76 25 74 27 72 27 72 8M96 29 96 48 92 44 92 31 94 29 96 29M72 29 74 29 76 31 76 44 72 48 72 29M77 45 91 45 95 49 73 49 77 45M103 7 125 7 121 11 107 11 103 7M122 12 126 8 126 27 124 27 122 25 122 12M123 28 121 30 107 30 105 28 107 26 121 26 123 28M102 8 106 12 106 25 104 27 102 27 102 8M126 29 126 48 122 44 122 31 124 29 126 29M102 29 104 29 106 31 106 44 102 48 102 29M107 45 121 45 125 49 103 49 107 45"
                                    fill="red"
                                    variants={variants}
                                    initial='initial'
                                    animate={controls}
                                    custom={4}
                                />
                                {/* Sixth 8 on Clock */}
                                <motion.path
                                    // d="
                                    // M 162.4 15.6 L 180 15.6 L 176.8 18.8 L 165.6 18.8 L 162.4 15.6 M 177.6 19.6 L 180.8 16.4 L 180.8 31.6 L 179.2 31.6 L 177.6 30 L 177.6 19.6 M 178.4 32.4 L 176.8 34 L 165.6 34 L 164 32.4 L 165.6 30.8 L 176.8 30.8 L 178.4 32.4 M 161.6 16.4 L 164.8 19.6 L 164.8 30 L 163.2 31.6 L 161.6 31.6 L 161.6 16.4 M 180.8 33.2 L 180.8 48.4 L 177.6 45.2 L 177.6 34.8 L 179.2 33.2 L 180.8 33.2 M 161.6 33.2 L 163.2 33.2 L 164.8 34.8 L 164.8 45.2 L 161.6 48.4 L 161.6 33.2 M 165.6 46 L 176.8 46 L 180 49.2 L 162.4 49.2 L 165.6 46
                                    // "
                                    d={digitSixCol}
                                    fill="red"
                                    variants={variants}
                                    initial='initial'
                                    animate={controls}
                                    custom={4}
                                />
                            </motion.svg>

                            <br />

                            <Button variant="secondary" size="sm" style={{ marginRight: '5px' }} onClick={() => setGo(true)}>
                                {/* PLAY-TRIANGLE SVG ON BUTTON */}
                                <motion.svg
                                    viewBox="-4 0 25 25"
                                    width={'18px'} height={'28px'}
                                >
                                    <motion.path d="M 2 2 L 2 26 L 17 14 L 2 2"
                                        fill="lightgrey"
                                        stroke='white'
                                        strokeWidth='2px'
                                    />
                                </motion.svg>
                            </Button>
                            <Button variant="secondary" onClick={() => setGo(false)}>
                                {/* STOP-SQUARE SVG ON BUTTON */}
                                <motion.svg
                                    viewBox="0 0 25 25"
                                    width={'15px'} height={'15px'}
                                >
                                    <motion.path d="M 24 0 L 0 0 L 0 24 L 24 24 L 24 0"
                                        fill="lightgrey"
                                        stroke='white'
                                        strokeWidth='2px'
                                    />
                                </motion.svg>
                            </Button>
                        </span>
                    </Card.Text>
                    
                </Card.Body>
                <Button onClick={() => setIsMove(!isMove)}>ON / OFF</Button>
            </Card>
        </>
    )
}
