import { motion, useAnimation } from "framer-motion";
import React, { useState, useEffect } from 'react'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/esm/Button';
// import Col from 'react-bootstrap/esm/Col';
// import Row from 'react-bootstrap/esm/Row';
// import GraphicsSVGN from '../graphics/GraphicsSVGN.png'
import SVG1 from '../graphics/SVG1.png';
import SVG2 from '../graphics/SVG2.png';
import SVG3 from '../graphics/SVG3.png';

export function SVGCard() {
    const controls1 = useAnimation()
    const controls2 = useAnimation()
    const controls3 = useAnimation()
    const [isMove, setIsMove] = useState(false);
    const duration = 4

    const variants1 = {
        initial: {
            x: '20',
        }
    }
    useEffect(() => {
        if (isMove) {
            controls1.start({
                x: [50, 300, 10, 0, 50],
                scale: [1, 0.8, 1, 1, 1],
                transition: { duration: duration }
            })
        } else {
            controls1.start({
                x: [50, 0, 200, 50],
                scale: [1, 0.8, 1, 1, 1],
                transition: { duration: duration }
            })
        }
    }, [isMove, controls1])

    const variants2 = {
        initial: {
            x: '20',
        }
    }
    useEffect(() => {
        if (isMove) {
            controls2.start({
                x: [50, 10, 200, 0, 50],
                scale: [1, 1.2, 1, 1],
                transition: { duration: duration }
            })
        } else {
            controls2.start({
                x: [50, 0, 250, 50],
                scale: [1, 1.2, 1, 1],
                transition: { duration: duration }
            })
        }
    }, [isMove, controls2])

    const variants3 = {
        initial: {
            x: '20',
        }
    }
    useEffect(() => {
        if (isMove) {
            controls3.start({
                x: [50, 0, 300, 0, 50],
                scale: [1, 1, 1.2, 1],
                transition: { duration: duration }
            })
        } else {
            controls3.start({
                x: [50, 0, 300, 50],
                scale: [1, 1, 1.2, 1],
                transition: { duration: duration }
            })
        }
    }, [isMove, controls3])

    return (
        <>
            <Card className="card-md">
                <Card.Body>
                    <Card.Title className='img-svgCard'

                    >Graphics - SVG - FramerMotion Animation</Card.Title>
                    <Card.Text>
                        <motion.img src={SVG1} height={170} float={'right'} alt='img'
                            variants={variants1}
                            initial='initial'
                            animate={controls1}
                        />
                        <motion.img src={SVG2} height={170} float={'right'} alt='img'
                            variants={variants2}
                            initial='initial'
                            animate={controls2}
                        />
                        <motion.img src={SVG3} height={170} float={'right'} alt='img'
                            variants={variants3}
                            initial='initial'
                            animate={controls3}
                        />
                        <br />
                        <Button onClick={() => setIsMove(state => !state)}>Animation</Button>
                        &nbsp;Vector or raster graphics and Animation Framer Motion
                        Every graphic element on all my websites is created by me (in InkScape or Gimp).
                        I invent many graphic elements myself.
                        All graphics and logos on this website were redrawn by me in the graphics program.
                        I can quickly create a set of new icons that are appropriate, 
                        with the optimal size and color, and can be used immediately for the current project!


                    </Card.Text>
                </Card.Body>
            </Card>
        </>
    )
}
