import React, { useState } from 'react'
import { motion } from 'framer-motion'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

export function Cross() {
    const iconVariants = {
        opened: { rotate: 45, transition: { duration: 1 } },
        closed: { rotate: 0, transition: { duration: 1 } },
    }
    // Checks the status of the top menu being pulled out
    const [isOpen, setIsOpen] = useState(false)

    return (
        <div>
            <Card className='card-sm'>
                <Card.Body>
                    <Card.Title>PLUS CROSS</Card.Title>
                    <Card.Text>
                        <svg
                            viewBox='-10 -15 40 40'
                            width="100px"
                            height="100px"
                        >
                            <motion.path
                                initial='opened'
                                variants={iconVariants}
                                animate={isOpen ? 'opened' : 'closed'}
                                //stroke="#ff0055"
                                d='M 8 5 L 13 5 L 13 8 L 8 8 L 8 13 L 5 13 L 5 8 L 0 8 L 0 5 L 5 5 L 5 0 L 8 0 L 8 5'
                                fill="grey"
                            />

                        </svg>
                    </Card.Text>

                </Card.Body>
                <Button onClick={() => setIsOpen(value => !value)} >Animation</Button>
            </Card>
        </div>
    )
}
