import { useState } from "react";
import { motion, useMotionValue, useTransform } from "framer-motion";
import React from 'react'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
//import GraphicSVG from '../graphics/GraphicSVG.png'

export function CheckV() {
    const [isChecked, setIsChecked] = useState(true);
    const pathLength = useMotionValue(0);
    const opacity = useTransform(pathLength, [0.05, 0.15], [0, 1]);

    return (
        <div>
            {/* <Card style={{ width: '10rem' }}> */}
            <Card className='card-sm'>
                {/* <div className="thumb" style={{ backgroundImage: `url(${GraphicSVG})` }}></div> */}
                <Card.Body>
                    <Card.Title>Check it</Card.Title>
                    <Card.Text>
                        <motion.span>
                            <svg width="110" height="110"
                                viewBox="0 0 130 130"
                            >
                                <motion.path
                                    d="M38 74.707l24.647 24.646L116.5 45.5"
                                    fill="transparent"
                                    strokeWidth="20"
                                    stroke="#39e"
                                    strokeLinecap="round"
                                    animate={{ pathLength: isChecked ? 0.9 : 0 }}
                                    style={{ pathLength: pathLength, opacity: opacity }}
                                //transition={{ duration: 2 }}
                                />
                            </svg>
                        </motion.span>                        
                    </Card.Text>
                   
                </Card.Body>
                <Button variant="primary" onClick={() => setIsChecked(!isChecked)}>Animation</Button>                    
            </Card>
        </div>
    )
}
