const digits3Col = {
    digits3: [
        // zero
        'M 73 7 L 95 7 L 91 11 L 77 11 L 73 7 M 92 12 L 96 8 L 96 27 L 94 27 L 92 25 L 92 12 M 72 8 L 76 12 L 76 25 L 74 27 L 72 27 L 72 8 M 96 29 L 96 48 L 92 44 L 92 31 L 94 29 L 96 29 M 72 29 L 74 29 L 76 31 L 76 44 L 72 48 L 72 29 M 77 45 L 91 45 L 95 49 L 73 49 L 77 45',
        // one
        'M 8 7 M 27 12 L 31 8 L 31 27 L 29 27 L 27 25 L 27 12 M 31 29 L 31 48 L 27 44 L 27 31 L 29 29 L 31 29',
        // two
        'M 8 7 L 30 7 L 26 11 L 12 11 L 8 7 M 27 12 L 31 8 L 31 27 L 29 27 L 27 25 L 27 12 M 28 28 L 26 30 L 12 30 L 10 28 L 12 26 L 26 26 L 28 28 M 7 29 L 9 29 L 11 31 L 11 44 L 7 48 L 7 29 M 12 45 L 26 45 L 30 49 L 8 49 L 12 45',
        // three
        'M 8 7 L 30 7 L 26 11 L 12 11 L 8 7 M 27 12 L 31 8 L 31 27 L 29 27 L 27 25 L 27 12 M 28 28 L 26 30 L 12 30 L 10 28 L 12 26 L 26 26 L 28 28 M 31 29 L 31 48 L 27 44 L 27 31 L 29 29 L 31 29 M 12 45 L 26 45 L 30 49 L 8 49 L 12 45',
        // four
        'M 8 7 M 27 12 L 31 8 L 31 27 L 29 27 L 27 25 L 27 12 M 28 28 L 26 30 L 12 30 L 10 28 L 12 26 L 26 26 L 28 28 M 7 8 L 11 12 L 11 25 L 9 27 L 7 27 L 7 8 M 31 29 L 31 48 L 27 44 L 27 31 L 29 29 L 31 29',
        // five
        'M 8 7 L 30 7 L 26 11 L 12 11 L 8 7 M 28 28 L 26 30 L 12 30 L 10 28 L 12 26 L 26 26 L 28 28 M 7 8 L 11 12 L 11 25 L 9 27 L 7 27 L 7 8 M 31 29 L 31 48 L 27 44 L 27 31 L 29 29 L 31 29 M 12 45 L 26 45 L 30 49 L 8 49 L 12 45',
        // six
        'M 8 7 L 30 7 L 26 11 L 12 11 L 8 7 M 28 28 L 26 30 L 12 30 L 10 28 L 12 26 L 26 26 L 28 28 M 7 8 L 11 12 L 11 25 L 9 27 L 7 27 L 7 8 M 31 29 L 31 48 L 27 44 L 27 31 L 29 29 L 31 29 M 7 29 L 9 29 L 11 31 L 11 44 L 7 48 L 7 29 M 12 45 L 26 45 L 30 49 L 8 49 L 12 45',
        // seven
        'M 8 7 L 30 7 L 26 11 L 12 11 L 8 7 M 27 12 L 31 8 L 31 27 L 29 27 L 27 25 L 27 12 M 7 8 L 7 8 M 31 29 L 31 48 L 27 44 L 27 31 L 29 29 L 31 29',
        // eight
        'M73 7 95 7 91 11 77 11 73 7 M92 12 96 8 96 27 94 27 92 25 92 12 M93 28 91 30 77 30 75 28 77 26 91 26 93 28 M72 8 76 12 76 25 74 27 72 27 72 8 M96 29 96 48 92 44 92 31 94 29 96 29 M72 29 74 29 76 31 76 44 72 48 72 29 M77 45 91 45 95 49 73 49 77 45',
        // nine
        'M 8 7 L 30 7 L 26 11 L 12 11 L 8 7 M 27 12 L 31 8 L 31 27 L 29 27 L 27 25 L 27 12 M 28 28 L 26 30 L 12 30 L 10 28 L 12 26 L 26 26 L 28 28 M 7 8 L 11 12 L 11 25 L 9 27 L 7 27 L 7 8 M 31 29 L 31 48 L 27 44 L 27 31 L 29 29 L 31 29 M 12 45 L 26 45 L 30 49 L 8 49 L 12 45',
    ]
}
export default digits3Col