import React, { useState } from "react";
import { motion } from "framer-motion";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

export function Circle() {
    const [isMove, setIsMove] = useState(true);
    let width = 50
    let height = 50

    return (
        <div>
            {/* <Card style={{ width: '10rem' }}> */}
            <Card className='card-sm'>
                {/* <div className="thumb" style={{ backgroundImage: `url(${GraphicSVG})` }}></div> */}
                <Card.Body>
                    <Card.Title>Stroke</Card.Title>
                    <Card.Text>
                        <span className="circle-line-rect">
                            <svg
                                viewBox="-5 -6 15 15"
                                width={width} height={height}>
                                <motion.path
                                    d="M5 1C10 1 10 8 5 8 0 8 0 1 5 1"
                                    fill="transparent"
                                    strokeWidth="1"
                                    stroke="#ff0055"
                                    strokeLinecap="round"
                                    animate={{ pathLength: isMove ? 1 : 0, }}
                                    //style={{ pathLength: pathLength, opacity: opacity }}
                                    transition={{ delay: 0, duration: 2 }}
                                />
                            </svg>
                            <svg
                                viewBox="-2 -5.5 15 15"
                                width={width} height={height}>
                                <motion.path
                                    d="M2 2 8 8M8 2 2 8"
                                    fill="transparent"
                                    strokeWidth="1"
                                    stroke="#00cc88"
                                    strokeLinecap="round"
                                    animate={{ pathLength: isMove ? 1 : 0, }}
                                    //style={{ pathLength: pathLength, opacity: opacity }}
                                    transition={{ delay: 1, duration: 2 }}
                                />
                            </svg>
                            <svg
                                viewBox="1 -5.2 15 15"
                                width={width} height={height}>
                                <motion.path
                                    d="M2 2 8 2 8 8 2 8 2 2"
                                    fill="transparent"
                                    strokeWidth="1"
                                    stroke="#0099ff"
                                    strokeLinecap="round"
                                    animate={{ pathLength: isMove ? 1 : 0, }}
                                    // style={{ pathLength: pathLength, opacity: opacity }}
                                    transition={{ delay: 2.4, duration: 2 }}
                                />
                            </svg>
                        </span>
                    </Card.Text>
                </Card.Body>
                <Button onClick={() => setIsMove(!isMove)}>Animation</Button>
            </Card>
        </div>
    )
}
