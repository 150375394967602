import React, { useState } from "react";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { SpielchenMini } from "./SpielchenMini";
import Modal from 'react-bootstrap/Modal';
import Spielchen from "./Spielchen";

export function CardSpielchen() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <div>
                <Card className='card-sm'>
                    <Card.Body>
                        <Card.Title>Can you win?</Card.Title>
                        <Card.Text>
                            <SpielchenMini />
                        </Card.Text>
                    </Card.Body>
                    <Button onClick={handleShow} >
                        START THE GAME
                    </Button>
                </Card>
            </div>
            <Modal size="lg" show={show} onHide={handleClose} animation={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>Play and try to win 3 points!</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Spielchen />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>                            
                        </Modal.Footer>
                    </Modal>

        </>
    )
}
