const digits4Col = {
    digits4: [
        // zero
        'M 103 7 L 125 7 L 121 11 L 107 11 L 103 7 M 122 12 L 126 8 L 126 27 L 124 27 L 122 25 L 122 12 M 102 8 L 106 12 L 106 25 L 104 27 L 102 27 L 102 8 M 126 29 L 126 48 L 122 44 L 122 31 L 124 29 L 126 29 M 102 29 L 104 29 L 106 31 L 106 44 L 102 48 L 102 29 M 107 45 L 121 45 L 125 49 L 103 49 L 107 45',
        // one
        'M 103 7 M 122 12 L 126 8 L 126 27 L 124 27 L 122 25 L 122 12 M 126 29 L 126 48 L 122 44 L 122 31 L 124 29 L 126 29',
        // two
        'M 103 7 L 125 7 L 121 11 L 107 11 L 103 7 M 122 12 L 126 8 L 126 27 L 124 27 L 122 25 L 122 12 M 123 28 L 121 30 L 107 30 L 105 28 L 107 26 L 121 26 L 123 28 M 102 8 L 102 8 M 102 29 L 104 29 L 106 31 L 106 44 L 102 48 L 102 29 M 107 45 L 121 45 L 125 49 L 103 49 L 107 45',
        // three
        'M 103 7 L 125 7 L 121 11 L 107 11 L 103 7 M 122 12 L 126 8 L 126 27 L 124 27 L 122 25 L 122 12 M 123 28 L 121 30 L 107 30 L 105 28 L 107 26 L 121 26 L 123 28 M 102 8 L 102 8 M 126 29 L 126 48 L 122 44 L 122 31 L 124 29 L 126 29 M 107 45 L 121 45 L 125 49 L 103 49 L 107 45',
        // four
        'M 103 7 M 122 12 L 126 8 L 126 27 L 124 27 L 122 25 L 122 12 M 123 28 L 121 30 L 107 30 L 105 28 L 107 26 L 121 26 L 123 28 M 102 8 L 106 12 L 106 25 L 104 27 L 102 27 L 102 8 M 126 29 L 126 48 L 122 44 L 122 31 L 124 29 L 126 29',
        // five
        'M 103 7 L 125 7 L 121 11 L 107 11 L 103 7 M 123 28 L 121 30 L 107 30 L 105 28 L 107 26 L 121 26 L 123 28 M 102 8 L 106 12 L 106 25 L 104 27 L 102 27 L 102 8 M 126 29 L 126 48 L 122 44 L 122 31 L 124 29 L 126 29 M 107 45 L 121 45 L 125 49 L 103 49 L 107 45',
        // six
        'M 103 7 L 125 7 L 121 11 L 107 11 L 103 7 M 123 28 L 121 30 L 107 30 L 105 28 L 107 26 L 121 26 L 123 28 M 102 8 L 106 12 L 106 25 L 104 27 L 102 27 L 102 8 M 126 29 L 126 48 L 122 44 L 122 31 L 124 29 L 126 29 M 102 29 L 104 29 L 106 31 L 106 44 L 102 48 L 102 29 M 107 45 L 121 45 L 125 49 L 103 49 L 107 45',
        // seven
        'M 103 7 L 125 7 L 121 11 L 107 11 L 103 7 M 122 12 L 126 8 L 126 27 L 124 27 L 122 25 L 122 12 M 102 8 L 102 8 M 126 29 L 126 48 L 122 44 L 122 31 L 124 29 L 126 29',
        // eight
        'M 103 7 L 125 7 L 121 11 L 107 11 L 103 7 M 122 12 L 126 8 L 126 27 L 124 27 L 122 25 L 122 12 M 123 28 L 121 30 L 107 30 L 105 28 L 107 26 L 121 26 L 123 28 M 102 8 L 106 12 L 106 25 L 104 27 L 102 27 L 102 8 M 126 29 L 126 48 L 122 44 L 122 31 L 124 29 L 126 29 M 102 29 L 104 29 L 106 31 L 106 44 L 102 48 L 102 29 M 107 45 L 121 45 L 125 49 L 103 49 L 107 45',
        // nine
        'M 103 7 L 125 7 L 121 11 L 107 11 L 103 7 M 122 12 L 126 8 L 126 27 L 124 27 L 122 25 L 122 12 M 123 28 L 121 30 L 107 30 L 105 28 L 107 26 L 121 26 L 123 28 M 102 8 L 106 12 L 106 25 L 104 27 L 102 27 L 102 8 M 126 29 L 126 48 L 122 44 L 122 31 L 124 29 L 126 29 M 107 45 L 121 45 L 125 49 L 103 49 L 107 45',
    ]
}
export default digits4Col