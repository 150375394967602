const digits5Col = {
    digits5: [
        // zero
        'M 137.4 15.6 L 155 15.6 L 151.8 18.8 L 140.6 18.8 L 137.4 15.6 M 152.6 19.6 L 155.8 16.4 L 155.8 31.6 L 154.2 31.6 L 152.6 30 L 152.6 19.6 M 136.6 16.4 L 139.8 19.6 L 139.8 30 L 138.2 31.6 L 136.6 31.6 L 136.6 16.4 M 155.8 33.2 L 155.8 48.4 L 152.6 45.2 L 152.6 34.8 L 154.2 33.2 L 155.8 33.2 M 136.6 33.2 L 138.2 33.2 L 139.8 34.8 L 139.8 45.2 L 136.6 48.4 L 136.6 33.2 M 140.6 46 L 151.8 46 L 155 49.2 L 137.4 49.2 L 140.6 46',
        // one
        'M 137.4 15.6 M 152.6 19.6 L 155.8 16.4 L 155.8 31.6 L 154.2 31.6 L 152.6 30 L 152.6 19.6 M 155.8 33.2 L 155.8 48.4 L 152.6 45.2 L 152.6 34.8 L 154.2 33.2 L 155.8 33.2',
        // two
        'M 137.4 15.6 L 155 15.6 L 151.8 18.8 L 140.6 18.8 L 137.4 15.6 M 152.6 19.6 L 155.8 16.4 L 155.8 31.6 L 154.2 31.6 L 152.6 30 L 152.6 19.6 M 153.4 32.4 L 151.8 34 L 140.6 34 L 139 32.4 L 140.6 30.8 L 151.8 30.8 L 153.4 32.4 M 136.6 16.4 L 136.6 16.4 M 136.6 33.2 L 138.2 33.2 L 139.8 34.8 L 139.8 45.2 L 136.6 48.4 L 136.6 33.2 M 140.6 46 L 151.8 46 L 155 49.2 L 137.4 49.2 L 140.6 46',
        // three
        'M 137.4 15.6 L 155 15.6 L 151.8 18.8 L 140.6 18.8 L 137.4 15.6 M 152.6 19.6 L 155.8 16.4 L 155.8 31.6 L 154.2 31.6 L 152.6 30 L 152.6 19.6 M 153.4 32.4 L 151.8 34 L 140.6 34 L 139 32.4 L 140.6 30.8 L 151.8 30.8 L 153.4 32.4 M 136.6 16.4 L 136.6 16.4 M 155.8 33.2 L 155.8 48.4 L 152.6 45.2 L 152.6 34.8 L 154.2 33.2 L 155.8 33.2 M 140.6 46 L 151.8 46 L 155 49.2 L 137.4 49.2 L 140.6 46',
        // four
        'M 137.4 15.6 M 152.6 19.6 L 155.8 16.4 L 155.8 31.6 L 154.2 31.6 L 152.6 30 L 152.6 19.6 M 153.4 32.4 L 151.8 34 L 140.6 34 L 139 32.4 L 140.6 30.8 L 151.8 30.8 L 153.4 32.4 M 136.6 16.4 L 139.8 19.6 L 139.8 30 L 138.2 31.6 L 136.6 31.6 L 136.6 16.4 M 155.8 33.2 L 155.8 48.4 L 152.6 45.2 L 152.6 34.8 L 154.2 33.2 L 155.8 33.2',
        // five
        'M 137.4 15.6 L 155 15.6 L 151.8 18.8 L 140.6 18.8 L 137.4 15.6 M 153.4 32.4 L 151.8 34 L 140.6 34 L 139 32.4 L 140.6 30.8 L 151.8 30.8 L 153.4 32.4 M 136.6 16.4 L 139.8 19.6 L 139.8 30 L 138.2 31.6 L 136.6 31.6 L 136.6 16.4 M 155.8 33.2 L 155.8 48.4 L 152.6 45.2 L 152.6 34.8 L 154.2 33.2 L 155.8 33.2 M 140.6 46 L 151.8 46 L 155 49.2 L 137.4 49.2 L 140.6 46',
        // six
        'M 137.4 15.6 L 155 15.6 L 151.8 18.8 L 140.6 18.8 L 137.4 15.6 M 153.4 32.4 L 151.8 34 L 140.6 34 L 139 32.4 L 140.6 30.8 L 151.8 30.8 L 153.4 32.4 M 136.6 16.4 L 139.8 19.6 L 139.8 30 L 138.2 31.6 L 136.6 31.6 L 136.6 16.4 M 155.8 33.2 L 155.8 48.4 L 152.6 45.2 L 152.6 34.8 L 154.2 33.2 L 155.8 33.2 M 136.6 33.2 L 138.2 33.2 L 139.8 34.8 L 139.8 45.2 L 136.6 48.4 L 136.6 33.2 M 140.6 46 L 151.8 46 L 155 49.2 L 137.4 49.2 L 140.6 46',
        // seven
        'M 137.4 15.6 L 155 15.6 L 151.8 18.8 L 140.6 18.8 L 137.4 15.6 M 152.6 19.6 L 155.8 16.4 L 155.8 31.6 L 154.2 31.6 L 152.6 30 L 152.6 19.6 M 136.6 16.4 L 136.6 16.4 M 155.8 33.2 L 155.8 48.4 L 152.6 45.2 L 152.6 34.8 L 154.2 33.2 L 155.8 33.2',
        // eight
        'M 137.4 15.6 L 155 15.6 L 151.8 18.8 L 140.6 18.8 L 137.4 15.6 M 152.6 19.6 L 155.8 16.4 L 155.8 31.6 L 154.2 31.6 L 152.6 30 L 152.6 19.6 M 153.4 32.4 L 151.8 34 L 140.6 34 L 139 32.4 L 140.6 30.8 L 151.8 30.8 L 153.4 32.4 M 136.6 16.4 L 139.8 19.6 L 139.8 30 L 138.2 31.6 L 136.6 31.6 L 136.6 16.4 M 155.8 33.2 L 155.8 48.4 L 152.6 45.2 L 152.6 34.8 L 154.2 33.2 L 155.8 33.2 M 136.6 33.2 L 138.2 33.2 L 139.8 34.8 L 139.8 45.2 L 136.6 48.4 L 136.6 33.2 M 140.6 46 L 151.8 46 L 155 49.2 L 137.4 49.2 L 140.6 46',
        // nine
        'M 137.4 15.6 L 155 15.6 L 151.8 18.8 L 140.6 18.8 L 137.4 15.6 M 152.6 19.6 L 155.8 16.4 L 155.8 31.6 L 154.2 31.6 L 152.6 30 L 152.6 19.6 M 153.4 32.4 L 151.8 34 L 140.6 34 L 139 32.4 L 140.6 30.8 L 151.8 30.8 L 153.4 32.4 M 136.6 16.4 L 139.8 19.6 L 139.8 30 L 138.2 31.6 L 136.6 31.6 L 136.6 16.4 M 155.8 33.2 L 155.8 48.4 L 152.6 45.2 L 152.6 34.8 L 154.2 33.2 L 155.8 33.2 M 140.6 46 L 151.8 46 L 155 49.2 L 137.4 49.2 L 140.6 46',
    ]
}
export default digits5Col