import React, { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

export function Lines() {
    const controlsText = useAnimation();
    const controlsTop = useAnimation();
    const controlsBottom = useAnimation();
    const [isMove, setIsMove] = useState(true);
    let width = 100
    let height = 100

    const variants = {
        initial: {
            pathLenght: 1,
            opacity: 0,
        }
    }

    useEffect(() => {
        if (isMove) {
            controlsText.start({
                opacity: 1,
            })
            controlsText.start({
                pathLength: [1, 0, 1],
                transition: { duration: 5 },
            })
        } else {
            controlsText.start({
                opacity: 1,
            })
            controlsText.start({
                pathLength: [1, 0, 1],
                transition: { duration: 5 },
            })
        }
    }, [isMove, controlsText])

    useEffect(() => {
        if (isMove) {
            controlsTop.start({
                opacity: 1,
            })
            controlsTop.start({
                pathLength: [1, 0.1, 1],
                transition: { duration: 2 },
            })
        } else {
            controlsTop.start({
                opacity: 1,
            })
            controlsTop.start({
                pathLength: [1, 0.1, 1],
                transition: { duration: 2 },
            })
        }
    }, [isMove, controlsTop])


    useEffect(() => {
        if (isMove) {
            controlsBottom.start({
                opacity: 1,
            })
            controlsBottom.start({
                pathLength: [1, 0.2, 1],
                transition: { duration: 2 },
            })
        }
    }, [isMove, controlsBottom])

    return (
        <div>
            <Card className='card-sm'>
                <Card.Body>
                    <Card.Title>Lines</Card.Title>
                    <Card.Text>
                        {/* Line above */}
                        <svg
                            viewBox="0 0 100 100"
                            width={width} height={height}>
                            {/* TEXT LINES */}
                            <motion.path
                                d="M 31 73 M 22 31 L 22 49 C 30 49 30 49 31 49 M 34 49 L 34 38 M 34 36 L 34 35 M 37 49 L 37 49 L 37 38 L 37 39 C 39 38 42 38 43 39 C 44 40 43 48 43 49 M 46 43 C 48 43 50 43 53 43 C 52 36 46 38 46 43 C 46 43 46 46 46 45 C 46 49 51 50 53 47 M 60 41 C 59 36 55 39 55 41 C 55 46 59 42 60 46 C 60 50 55 50 55 46"
                                fill="transparent"
                                strokeWidth="1"
                                stroke="green"
                                strokeLinecap="round"
                                variants={variants}
                                initial='initial'
                                animate={controlsText}
                            />

                            <motion.path
                                d="M 7 55 L 80 55"
                                fill="transparent"
                                strokeWidth="1"
                                stroke="lightGreen"
                                strokeLinecap="round"
                                variants={variants}
                                initial='initial'
                                animate={controlsTop}
                            />

                            {/* Line under */}
                            <motion.path
                                d='M 73 60 L 0 60'
                                fill="transparent"
                                strokeWidth="1"
                                stroke="lightGreen"
                                strokeLinecap="round"
                                variants={variants}
                                initial='initial'
                                animate={controlsBottom}
                            />
                        </svg>
                    </Card.Text>
                </Card.Body>
                <Button onClick={() => setIsMove(!isMove)}>Animation</Button>
            </Card>
        </div>
    )
}
